//@font-face {
//  font-family: 'railwayregular';
//  src: url('./assets/fonts/Railway-webfont.eot');
//  src: url('./assets/fonts/Railway-webfont.eot?#iefix') format('embedded-opentype'),
//  url('./assets/fonts/Railway-webfont.woff') format('woff'),
//  url('./assets/fonts/Railway-webfont.ttf') format('truetype'),
//  url('./assets/fonts/Railway-webfont.svg#railwayregular') format('svg');
//  font-weight: normal;
//  font-style: normal;
//
//}

a {
  text-decoration: none;
}

//html {
//  font-family: railwayregular, Arial, serif !important;
//}

body {
  position: relative;
  margin: 0;
  min-height: 100%;
}

@media only screen and (min-width: 769px) {
  body {
    //padding-bottom: 10rem;
  }
}

*:focus-within {
  border: 0;
  outline: none;
}
